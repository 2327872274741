import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      selectedEvent: (state) => state.events.selectedEvent,
      selectedEventId: (state) => state.storedStates.selectedEventId
    })
  },
  data () {
    return {
      isLoading: true
    }
  },

  async created () {
    try {
      await this.getEventInfo()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async getEventInfo () {
      this.$store.commit('storedStates/SET_SELECTED_VENUE', 'Event')
      const { id } = this.$route.params
      if (id !== this.selectedEventId) {
        console.log('id not equals to selectedEventId')
        console.log(id)
        console.log(this.selectedEventId)
        this.$store.commit('storedStates/SET_SELECTED_EVENT_ID', id)
      }
      try {
        await this.$store
          .dispatch('events/getEvent', this.selectedEventId)
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
    }
  },
  watch: {
    async selectedEventId (val) {
      await this.getEventInfo()
    }
  }
}
